import { getOrdersFromFireBase } from "../store/actions/orderAction";
// import { getAllSessions } from "../store/actions/tableSessionsAction";
import { firebase } from "../store/firebase";

export async function firebaseOrderListner(blId, orders, dispatch, notify) {
  const primaryStatus = {
    localOrder: "Local_Order_Konigsallee",
    isCancel: "isCancelled",
    businessID: "businessLocationId",
  };
  const orderFilterStatus = {
    cooked: "cooked",
    payment: "paid",
    served: "true",
  };
  try {
    await firebase
      .firestore()
      .collection(primaryStatus?.localOrder)
      // .where("sessionId", "==", "63ea1b8ac394b54710c9658f")
      .where(primaryStatus?.isCancel, "==", false)
      .where(primaryStatus?.businessID, "==", blId)
      // .where(primaryStatus?.isParent, "==", false)
      .onSnapshot((snapshot) => {
        let updatedData = snapshot?.docs?.map((doc) => doc?.data());
        let filterdData = updatedData?.filter(
          (_item) =>
            _item.status !== orderFilterStatus?.cooked ||
            _item.payment_status !== orderFilterStatus?.payment ||
            _item.serve_status !== orderFilterStatus?.served
        );
        // storedOrdersLength = filterdData.length;
        // console.log(storedOrdersLength, "the length");
        // if (filterdData?.length !== storedOrdersLength) {
        //   console.log("firebase hit");
        //   dispatch(getAllSessions(blId));
        // }
        for (var i = 0; i < filterdData?.length; i++) {
          if (filterdData[i].salesEntries.length) {
            let findObj = orders?.find(
              (_item) => _item?._id === filterdData[i]?._id
            );
            if (
              !findObj ||
              filterdData[i].salesEntries.length > findObj.salesEntries.length
            ) {
              notify();
            }
          }
        }
        dispatch(getOrdersFromFireBase(filterdData));
      });
  } catch (error) {
    console.log(error);
    dispatch(getOrdersFromFireBase([]));
  }
}
